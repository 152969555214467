"use strict";

let project = document.getElementsByClassName("project");
let projectgrid = document.getElementById("projects");
let navMain = document.getElementById("nav-main");
let ViewportH = window.innerHeight;

if (projectgrid) { //pages with grid

    function setprojectTop() { // own function to get the value after img load in promise
      let projectTop = project[3].offsetTop;
      ////console.log('projectTop gettet');
      return projectTop;
    }
  
    function setNavPosition() {
      
      let projectHeight = project[0].offsetHeight; 
      let projectBottom = ViewportH - setprojectTop() - projectHeight - 35 ;
      
      navMain.style.setProperty('--margin-bottom', projectBottom + "px");// big screen, align bootom
      navMain.style.setProperty('--margin-top', setprojectTop() + "px");//small screns, align top

      navMain.style.opacity="1"; //avoid fout
      
      //console.log('setnaved');
      return projectBottom; //make it standalone to be more modular and scoped
    
    }

  //https://stackoverflow.com/questions/11071314/javascript-execute-after-all-images-have-loaded
  Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => { 
    //console.log('before projetop');
    setprojectTop() 
    //console.log('before setnaved'); 
    setNavPosition();
    //console.log('after setnaved'); 
    sessionStorage.setItem('key', setNavPosition());
    sessionStorage.setItem('key2', setprojectTop()); 
    //console.log('storage settted')
  });

  //let projectTop = project[3].offsetTop; //to be available by session storage, bad cause out of promise
  
  
  window.addEventListener('resize', setNavPosition);

}


if (!projectgrid) { // pages
  const data = sessionStorage.getItem('key');
  const data2 = sessionStorage.getItem('key2');
  ////console.log(`this is ${data} and ${data2}`); 
  navMain.style.setProperty('--margin-bottom', data + "px");// big screen, align bootom
  navMain.style.setProperty('--margin-top', data2 + "px");//small screns, align top
  navMain.style.opacity="1";
}


///KEYS

// - make more modular
// - make as plugin
// - data asset


